



















































import Vue from "vue";
import {
  GeoCodeResult,
  LatLng,
  LocationMarkers,
  LocationPoints,
  MapAutoCompleteResult
} from "@/interfaces/onboarding/onboarding";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getGoogleMapsAPI } from "gmap-vue";
import { mapGetters, mapMutations } from "vuex";
import { GET_ONBOARDING_LOADING } from "@/store/modules/onboarding/constants";
import { ROOT_ERROR } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "RecruiterCompanyLocation",
  data() {
    return {
      company_location: "" as string,
      location_points: {
        lat: 25.276987,
        lng: 55.296249
      } as LocationPoints,
      map_markers: [
        {
          position: {
            lat: 25.276987,
            lng: 55.296249
          }
        }
      ] as LocationMarkers[],
      map_options: {
        clickableIcons: false,
        streetViewControl: false,
        panControlOptions: true,
        mapTypeControl: true,
        zoomControl: true,
        fullscreenControl: false
      }
    };
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google(): any {
      return getGoogleMapsAPI();
    },
    ...mapGetters("onboarding", {
      get_onboarding_loading: GET_ONBOARDING_LOADING
    })
  },
  watch: {
    async google(new_value, old_value) {
      if (new_value && !old_value) await this.get_readable_address();
    }
  },
  async mounted() {
    // Get user current location
    navigator.geolocation.getCurrentPosition(
      // If user allow current location => update location points & map marker
      async (position) => {
        this.location_points = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        this.map_markers = [
          {
            position: this.location_points
          }
        ];
        await this.get_readable_address();
      },
      // If user deny current location => show error notification
      () => {
        this.global_error("Please enable location");
      }
    );
  },
  methods: {
    ...mapMutations({
      global_error: ROOT_ERROR
    }),
    // get_google() {
    //   return this.google;
    // },
    /**
     * Submit form function
     */
    submit_form() {
      if (this.company_location) {
        this.$emit("update_stepper", {
          step: 4,
          value: this.company_location
        });
      } else this.reset_data();
    },
    /**
     * Function for location auto complete
     * @param place
     */
    input_place(place: MapAutoCompleteResult) {
      // Get lat, lng position
      const location = place.geometry.location;
      this.location_points = {
        lat: location.lat(),
        lng: location.lng()
      };
      this.map_markers = [
        {
          position: this.location_points
        }
      ];
      this.company_location = place.formatted_address;
    },
    /**
     * Function to update map markers after map marker drag
     * @param latLng: LatLng
     */
    change_location({ latLng }: { latLng: LatLng }) {
      this.map_markers = [
        {
          position: {
            lat: latLng.lat(),
            lng: latLng.lng()
          }
        }
      ];
    },
    /**
     * Function to update location & map markers on map click
     * @param latLng: LatLng
     */
    update_location_by_click({ latLng }: { latLng: LatLng }) {
      this.map_markers = [
        {
          position: {
            lat: latLng.lat(),
            lng: latLng.lng()
          }
        }
      ];
      this.update_location({ latLng });
    },
    /**
     * Function to update map points & get readable address from points
     * @param latLng: LatLng
     */
    async update_location({ latLng }: { latLng: LatLng }) {
      // Update location points
      this.location_points = {
        lat: latLng.lat(),
        lng: latLng.lng()
      };
      await this.get_readable_address();
    },
    /**
     * Function to get readable address from points
     */
    async get_readable_address() {
      if (!this.google) return;
      const geocoder = new this.google.maps.Geocoder();
      // Get human-readable address from LatLng points
      geocoder.geocode(
        { location: this.location_points },
        (result: GeoCodeResult[], status: string) => {
          // If status okay & result return from geocoding API
          if (
            status === this.google.maps.GeocoderStatus.OK &&
            result.length > 0
          ) {
            this.company_location = result[0].formatted_address;
            // Update auto complete field
            const aa = this.$refs.map_autocomplete as Vue;
            const bb = aa.$refs.input as Element;
            bb.setAttribute("value", this.company_location);
          }
          // If status okay or no result return from geocoding API => reset data
          else this.reset_data();
        }
      );
    },
    /**
     * Function to reset data
     */
    reset_data() {
      this.company_location = "";
      this.location_points = {
        lat: 25.276987,
        lng: 55.296249
      };
      this.map_markers = [
        {
          position: {
            lat: 25.276987,
            lng: 55.296249
          }
        }
      ];
    }
  }
});
