
































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { GET_UNIQUE_SECTORS } from "@/store/modules/common/constants";
import { GET_ONBOARDING_LOADING } from "@/store/modules/onboarding/constants";
import { FormFieldRulesString } from "@/interfaces/v_form";

export default Vue.extend({
  name: "RecruiterCompanyIndustry",
  data() {
    return {
      form: false,
      company_industry_rules: [
        (value: string) =>
          !!value || this.$t("recruiter-onboarding.company-industry-error")
      ] as FormFieldRulesString[],
      company_industry: null as string | null
    };
  },
  mounted() {
    if (this.unique_sectors.length === 0) this.init_sectors();
  },
  computed: {
    ...mapGetters("common", {
      unique_sectors: GET_UNIQUE_SECTORS
    }),
    ...mapGetters("onboarding", {
      get_onboarding_loading: GET_ONBOARDING_LOADING
    })
  },
  methods: {
    ...mapActions("common", {
      init_sectors: GET_UNIQUE_SECTORS
    }),
    submit_form() {
      if (this.company_industry)
        this.$emit("update_stepper", {
          step: 5,
          value: this.company_industry
        });
    }
  }
});
