






























import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_ONBOARDING_LOADING } from "@/store/modules/onboarding/constants";
import { FormFieldRulesString } from "@/interfaces/v_form";

export default Vue.extend({
  name: "RecruiterCompanyName",
  data() {
    return {
      form: false as boolean,
      company_name_rules: [
        (value: string) =>
          !!value.trim() || this.$t("rules.required").toString()
      ] as FormFieldRulesString[],
      company_name: "" as string
    };
  },
  computed: {
    ...mapGetters("onboarding", {
      get_onboarding_loading: GET_ONBOARDING_LOADING
    })
  },
  methods: {
    submit_form() {
      this.$emit("update_stepper", {
        step: 1,
        value: this.company_name
      });
    }
  }
});
