




































import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_ONBOARDING_LOADING } from "@/store/modules/onboarding/constants";
import { FormFieldRulesString } from "@/interfaces/v_form";

export default Vue.extend({
  name: "RecruiterCompanyDescription",
  data() {
    return {
      form: false as boolean,
      company_description_rules: [
        (value: string) =>
          !!value.trim() ||
          this.$t("recruiter-onboarding.company-description-required"),
        (value: string) =>
          value.trim().length > 70 ||
          this.$t("recruiter-onboarding.company-description-rule"),
        (value: string) =>
          value.trim().length < 700 ||
          this.$t("recruiter-onboarding.company-description-rule-max")
      ] as FormFieldRulesString[],
      company_description: "" as string
    };
  },
  computed: {
    ...mapGetters("onboarding", {
      get_onboarding_loading: GET_ONBOARDING_LOADING
    })
  },
  methods: {
    submit_form() {
      this.$emit("update_stepper", {
        step: 7,
        value: this.company_description
      });
    }
  }
});
